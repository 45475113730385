import React from "react";
import { graphql } from "gatsby";
import HeroCard from "../components/HeroCard";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import Projects from "./Projects";
import Service from "./Services";
import Section from "./Section";
import Services from "./Services";

const Home = (props) => {
  const homeJson = props.data.homeData.frontmatter;
  const { services } = props.data.services.frontmatter;

  return (
    <Layout bodyClass="page-home">
      <SEO
        title={homeJson.title}
        description={homeJson.SEODescription}
        imageUrl={
          props.data.seoImage.frontmatter.headerImage.childImageSharp.fixed.src
        }
        path={homeJson.path}
      />
      <HeroCard
        title={homeJson.headerTitle}
        subtitle={homeJson.headerSubtitle}
        quote={homeJson.testimonial}
        quoteAuthor={homeJson.testimonialAuthor}
        image={homeJson.headerImage}
        actionBtnText={homeJson.actionBtnText}
        actionBtnUrl={homeJson.actionBtnUrl}
        size="large"
      />

      <Projects />

      <Section heading="How can we help?">
        <Services services={services} />
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: {
        frontmatter: { isVisible: { eq: true } }
        fileAbsolutePath: { regex: "/solutions/" }
      }
      sort: { fields: [frontmatter___teaserTitle], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            teaserTitle
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    allFeaturesJson {
      edges {
        node {
          id
          title
          description
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    services: markdownRemark(fileAbsolutePath: { regex: "/services.md/" }) {
      frontmatter {
        services {
          btnText
          subTitle
          title
          url
        }
      }
    }
    homeData: markdownRemark(fileAbsolutePath: { regex: "/home.md/" }) {
      frontmatter {
        path
        title
        SEODescription
        headerImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        headerTitle
        headerSubtitle
        testimonial
        testimonialAuthor
        actionBtnText
        actionBtnUrl
      }
    }
    seoImage: markdownRemark(fileAbsolutePath: { regex: "/home.md/" }) {
      frontmatter {
        headerImage {
          childImageSharp {
            fixed(width: 600) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
          extension
          publicURL
        }
      }
    }
  }
`;

export default Home;
